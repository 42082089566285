// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPBvWyTNJwXm39EcrY8sdKlZa7afZYGLQ",
  authDomain: "galeana-biz-a94a6.firebaseapp.com",
  projectId: "galeana-biz-a94a6",
  storageBucket: "galeana-biz-a94a6.appspot.com",
  messagingSenderId: "419979533354",
  appId: "1:419979533354:web:d25b3e707ad84c2856b753",
  measurementId: "G-JRT6Z78WPT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import AddToAllPages from "../../components/AddToAllPages";

import "../../styles/pages/HomePage.scss";
import "../../styles/components/CompanyAndProductCards/SponsorCard.scss";

import { getUserDetails } from "../../actions/userAction";

import {
  CartGetTotalItemAction,
  CartSynchAction,
} from "../../actions/cartActions";

import AllHomeProducts from "./AllHomeProducts";

export default function HomePage() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userDetails);
  const isDriver = user?.user?.profile?.role === "Galeana Biz driver";

  const cartWasSynced = useRef(null);

  useEffect(() => {
    dispatch(getUserDetails());

    if (
      user?.user?.profile?.role === "Galeana Biz driver" ||
      user?.user?.profile?.role === "Admin"
    ) {
      localStorage.setItem("adminDataPass", '"124"');
    }
  }, [dispatch]);

  useEffect(() => {
    if (user && cartWasSynced.current === null) {
      cartWasSynced.current = true;
      dispatch(CartSynchAction());
      dispatch(CartGetTotalItemAction());
    }
  }, [user, dispatch]);

  const DriverHome = () => {
    if (!isDriver) return null;

    localStorage.setItem("adminDataPass", '"124"');

    return (
      <div className="galeanaBizDriverContainer">
        <Link to="/driver" className="goToDriverPage">
          Go to Driver Page
        </Link>
      </div>
    );
  };

  const Home = () => {
    if (isDriver) return null;

    return <AllHomeProducts />;
  };

  return (
    <AddToAllPages>
      <Home />

      <DriverHome />
    </AddToAllPages>
  );
}

import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserCompany } from "./actions/userAction";
import { DriverProfileActions } from "../src/actions/driver-profileActions";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

// Private Route Handlers
import ActiveSubRoutes from "./components/privateRoute/ActiveSubRoutes";
import PrivCartRoute from "./components/privateRoute/PrivCartRoute";
import PrivateDriverRoute from "./components/privateRoute/PrivateDriverRoute";
import PrivRouteCompany from "./components/privateRoute/PrivRouteCompany";
import PrivateUserorAdminRoute from "./components/privateRoute/PrivateUserorAdminRoute";
import AdminOrDiverRoute from "./components/privateRoute/AdminOrDriverRoute";
import PrivateAdminDriver from "./components/privateRoute/PrivateAdminDriver";
import UserRoute from "./components/privateRoute/UserRoute";

// Public Routes
import CompanyHomePage from "./pages/CompanyPage/CompanyHomePage";
import HomePage from "./pages/home/HomePage";
import AllHomeCompanies from "./pages/home/AllHomeCompanies";
import Login from "./pages/authentication/Login";
import PageNotFound from "./pages/PageNotFound";
import ProfilePage from "./pages/profilePage/ProfilePage";

// User Only Routes
import Cart from "./pages/Cart/CartItem";
import FavoritesPage from "./pages/favoritePage/FavoritesPage";
import ProductDetailsPage from "./pages/products/ProductDetailsPage";
import RatingandCommentCompanies from "./pages/CompanyPage/views/reviews/RatingandCommentCompanies";
import UserAdress from "./pages/adress/usersadress/UserAdress";
import UserPostAdress from "./pages/adress/usersadress/UserPostAdress";
import UserPutAdress from "./pages/adress/usersadress/UserPutAdress";

// Subscription Routes
import AddProduct from "./pages/products/AddProduct";
import AllOrderUser from "./pages/orders/customer/views/AllOrderUser";
import CheckOut from "./pages/checkout/CheckOut";
import CompanyPostAdress from "./pages/adress/companyadress/CompanyPostAdress";
import CompanyPutAdress from "./pages/adress/companyadress/CompanyPutAdress";
import CreateCompany from "./pages/CreateCompany";
import MyBusinessDashboardPage from "./pages/MyCompanyPage/MyBusinessDashboardPage";
import MyCompanyProductsPage from "./pages/MyCompanyPage/pages/products/MyCompanyProductsPage";
import TableProducts from "./pages/MyCompanyPage/pages/products/TableProducts/TableProducts";
import OrderCompanyProducts from "./pages/orders/business/OrderCompanyProducts";
import OrderDetails from "./pages/orders/customer/views/OrderDetails";
import PostAbout from "./pages/CompanyPage/companybar/AboutCompany/PostAbout";
import PostContact from "./pages/CompanyPage/companybar/ContactCompany/PostContact";
import PostSocial from "./pages/CompanyPage/companybar/SocialCompany/PostSocial";
import PutAbout from "./pages/CompanyPage/companybar/AboutCompany/PutAbout";
import PutContact from "./pages/CompanyPage/companybar/ContactCompany/PutContact";
import PutSocial from "./pages/CompanyPage/companybar/SocialCompany/PutSocial";
import UpdateCompany from "./pages/UpdateCompany";
import UpdateProduct from "./pages/products/UpdateProduct";
import ProductReviews from "./pages/products/ProductReviews";
import Customization from "./pages/MyCompanyPage/pages/customization/Customization";
import OpeningHours from "./pages/MyCompanyPage/pages/openinghours/OpeningHours";

// Driver Routes
import HomeDriver from "./pages/Driver/HomeDriver";
import OrderDriver from "./pages/Driver/OrderDriver";
import OrdersInProgress from "./pages/Driver/pages/admin/OrdersInProgress";
import OrdersListDriver from "./pages/Driver/pages/OrdersListDriver";
import SponsorPage from "./pages/SponsorPage";
import DriversPage from "./pages/Driver/pages/admin/DriversPage";
import OrdersStatus from "./pages/Driver/pages/admin/OrdersStatus";
import OrderHistory from "./pages/Driver/pages/OrderHistory";

//Admin
import Admin from "./pages/Admin/Admin";
import ServiceDetailsPage from "./pages/company_service/views/ServiceDetailsPage";
import BusinessService from "./pages/MyCompanyPage/pages/services/BusinessService";

function App() {
  const dispatch = useDispatch();
  const { user, company } = useSelector((state) => state.userDetails);
  const isDriver = user?.user?.profile?.role === "Galeana Biz driver";

  useEffect(() => {
    if (company === null || company) {
      return;
    }

    dispatch(getUserCompany());
  }, [dispatch, company]);

  useEffect(() => {
    if (isDriver) {
      dispatch(DriverProfileActions());
    }
  }, [dispatch, isDriver]);

  return (
    <BrowserRouter>
      <DetectPageChange />
      <Routes>
        <Route element={<UserRoute />}>
          {/* user, admin and delivery */}

          <Route
            path="/profile"
            element={<Element title={"Profile"} element={ProfilePage} />}
          />
          <Route path="/profile/address" element={<UserAdress />} />
          <Route path="/profile/add-address" element={<UserPostAdress />} />
          <Route path="/profile/update-address" element={<UserPutAdress />} />

          {/* Driver */}
          <Route element={<PrivateDriverRoute />}>
            <Route
              path="/driver"
              element={<Element title={"AllOrders"} element={HomeDriver} />}
            />
            <Route
              path="/driver-order/:transactionId"
              element={<Element title={"OrderDetail"} element={OrderDriver} />}
            />
            <Route
              path="/driver-order-list"
              element={
                <Element title={"OrderList"} element={OrdersListDriver} />
              }
            />
            <Route
              path="/order-history"
              element={
                <Element title={"OrderHistory"} element={OrderHistory} />
              }
            />

            <Route element={<PrivateAdminDriver />}>
              <Route
                path="/driver-order-in-progress"
                element={
                  <Element
                    title={"OrdersInProgress"}
                    element={OrdersInProgress}
                  />
                }
              />

              <Route
                path="/driver-orders-status"
                element={
                  <Element title={"OrderStatus"} element={OrdersStatus} />
                }
              />

              <Route
                path="/driver-order/:transactionId/:driverId/"
                element={<OrderDriver />}
              />

              <Route
                path="/drivers"
                element={<Element title={"Drivers"} element={DriversPage} />}
              />
            </Route>
          </Route>

          <Route element={<PrivateUserorAdminRoute />}>
            {/* companies */}

            <Route
              path="/product/:businessId/:productId/reviews"
              element={<ProductReviews />}
            />

            <Route element={<PrivCartRoute />}>
              <Route
                path="/checkout"
                element={<Element title={"CheckOut"} element={CheckOut} />}
              />
            </Route>

            {/* orders */}
            <Route
              path="/myorders"
              element={<Element title={"MyOrders"} element={AllOrderUser} />}
            />
            <Route
              path="/order-details/:transaction_id/"
              element={<Element title={"MyOrders"} element={OrderDetails} />}
            />

            {/* users with subscription */}
            <Route element={<ActiveSubRoutes />}>
              <Route
                path="/mybusiness"
                element={
                  <Element
                    title={"MyBusiness"}
                    element={MyBusinessDashboardPage}
                  />
                }
              />
              <Route
                path="/business/createdCompany"
                element={<CreateCompany />}
              />

              {/* users with company */}
              <Route element={<PrivRouteCompany />}>
                <Route
                  path="/mybusiness/adress/update"
                  element={<CompanyPutAdress />}
                />
                <Route
                  path="/mybusiness/adress/created"
                  element={<CompanyPostAdress />}
                />
                <Route
                  path="/mybusiness/orders-company"
                  element={
                    <Element
                      title={"OrdersCompany"}
                      element={OrderCompanyProducts}
                    />
                  }
                />
                <Route
                  path="/mybusiness/products"
                  element={
                    <Element
                      title={"Products"}
                      element={MyCompanyProductsPage}
                    />
                  }
                />
                <Route
                  path="/mybusiness/table-products"
                  element={
                    <Element title={"Products"} element={TableProducts} />
                  }
                />
                <Route
                  path="/mybusiness/addproduct"
                  element={<Element title={"Porduct"} element={AddProduct} />}
                />

                <Route
                  path="/product/:id/updateProduct"
                  element={
                    <Element title={"Porduct"} element={UpdateProduct} />
                  }
                />
                <Route
                  path="/business/:_id/updateCompany"
                  element={
                    <Element title={"mybusiness"} element={UpdateCompany} />
                  }
                />

                <Route
                  path="/mybusiness/services"
                  element={
                    <Element title={"Services"} element={BusinessService} />
                  }
                />

                {/* about */}

                <Route path="/post-about-company" element={<PostAbout />} />
                <Route path="/put-about-company" element={<PutAbout />} />

                {/* contact */}

                <Route path="/post-contact-company" element={<PostContact />} />
                <Route path="/put-contact-company" element={<PutContact />} />

                {/* social*/}

                <Route path="/post-social-company" element={<PostSocial />} />
                <Route path="/put-social-company" element={<PutSocial />} />

                {/* sponsor */}
                <Route path="/sponsor" element={<SponsorPage />} />

                {/* Customization */}
                <Route
                  path="/mybusiness/customization"
                  element={<Customization />}
                />

                <Route
                  path="/mybusiness/setings-hours"
                  element={
                    <Element title={"HoursSettings"} element={OpeningHours} />
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route element={<AdminOrDiverRoute />}>
            <Route
              path="/admin"
              element={<Element title={"Admin"} element={Admin} />}
            />
          </Route>
        </Route>

        {/* public routes */}
        <Route path="/" element={<Element element={HomePage} />} />

        <Route
          path="/home-business"
          element={<Element title={"Business"} element={AllHomeCompanies} />}
        />

        <Route path="/business/:businessId" element={<CompanyHomePage />} />

        <Route
          path="/product/:businessId/:productId/"
          element={<Element title={"Products"} element={ProductDetailsPage} />}
        />
        <Route
          path="/business/:companyId/reviewsCompany/"
          element={<RatingandCommentCompanies />}
        />

        <Route
          path="/cart"
          element={<Element title={"Cart"} element={Cart} />}
        />
        <Route
          path="/favorites"
          element={<Element title={"Favorite"} element={FavoritesPage} />}
        />
        <Route
          path="/login"
          element={<Element title={"Login"} element={Login} />}
        />

        {/* ServiceCompanyPublic */}
        <Route
          path="/business/service/:slug"
          element={
            <Element title={"Service-Detail"} element={ServiceDetailsPage} />
          }
        />
        {/* this needs to be the last element */}
        <Route path="*" element={<Element element={PageNotFound} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const Element = ({ title, element: Element }) => {
  document.title = title ? `${title} - GaleanaBiz` : `GaleanaBiz`;

  return <Element />;
};

function DetectPageChange() {
  const location = useLocation();

  const currentTime = new Date();
  const formattedTime = currentTime.toISOString();

  const adminDataPassId = JSON.parse(localStorage.getItem("adminDataPass"));

  useEffect(() => {
    if (adminDataPassId === "124") {
      return;
    } else {
      const sendData = async (e) => {
        try {
          const docRef = await addDoc(collection(db, "pages visited"), {
            url: location.pathname,
            timestamp: formattedTime,
          });
        } catch (e) {
          return;
        }
      };

      sendData();
    }
  }, [location]);
}
